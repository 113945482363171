<template>
  <div class="appContainer usersBox flex-between">
    <div class="leftBox bb">
      <div class=" header flex-end">
        <!-- <el-input
          placeholder="请输入用户名/角色名"
          v-model="leftForm.filter"
          @input="searchFn"
          clearable
        /> -->

        <el-tooltip effect="dark" content="新增" placement="top-start">
          <el-button
            icon="el-icon-plus"
            style="margin-left:10px"
            class="font18"
            type="text"
            @click="addFn"
          />
        </el-tooltip>
        <el-tooltip effect="dark" content="刷新列表" placement="top-start">
          <el-button
            icon="el-icon-refresh"
            style="margin-left:10px"
            class="font18"
            type="text"
            @click="refreshListFn"
          />
        </el-tooltip>
        <!-- </div> -->
      </div>

      <el-scrollbar
        class="list"
        wrap-class="scrollbar-wrapper"
        v-if="userList.length"
        v-loading="loadingList"
      >
        <span
          v-for="(item, key) in userList"
          class="pointer flex-between text"
          :key="key"
          @click="selectUserFn(item)"
          :class="{ active: item.spaceUserId == currentId }"
        >
          <label>{{ item.name }}</label>
          <el-popconfirm title="确定删除该用户吗？" @confirm="deleUserFn(item)">
            <el-button
              type="danger"
              size="mini"
              class="deleBtn "
              slot="reference"
              >删除</el-button
            >
          </el-popconfirm>
        </span>
      </el-scrollbar>
      <p v-else class="empty bb text-center">暂无数据</p>
    </div>
    <div class="rightBox bb">
      <template v-if="currentId">
        <el-card class="box-card" v-for="item in cardList" :key="item.id">
          <div slot="header">
            <span class="fW600">{{ item.name }}</span>
          </div>
          <template v-if="item.id == 'base'">
            <div class="line-box">
              <b>用户名：</b>
              <span class="text">{{ userDetial.detial.username }}</span>
            </div>
            <div class="line-box">
              <b>姓名：</b>
              <span class="text">{{ userDetial.detial.nickname }}</span>
            </div>

            <div class="line-box">
              <b>手机号：</b>
              <span
                class="text"
                v-if="
                  userDetial.detial.cellphone &&
                    userDetial.detial.cellphone != ''
                "
                >{{ userDetial.detial.cellphone }}</span
              >
              <span v-else class="text">空</span>
            </div>
            <div class="line-box">
              <b>邮箱：</b>
              <span
                class="text"
                v-if="userDetial.detial.email && userDetial.detial.email != ''"
                >{{ userDetial.detial.email }}</span
              >
              <span v-else class="text">空</span>
            </div>
          </template>
          <template v-if="item.id == 'weChat'">
            <div class="line-box">
              <b>企业微信ID：</b
              ><span
                class="text"
                v-if="
                  userDetial.detial.workWechatUserId &&
                    userDetial.detial.workWechatUserId != ''
                "
                >{{ userDetial.detial.workWechatUserId }}</span
              >
              <span v-else class="text">空</span>
            </div></template
          >
          <template v-if="item.id == 'role'">
            <!--  :label="role.name" -->
            <el-checkbox
              class="checkName"
              label=""
              v-for="(role, idx) in roleList"
              :key="idx"
              style=""
              @change="changeRoleFn(role, $event)"
              v-model="role.checked"
            >
              <span class="">{{ role.name }}</span></el-checkbox
            >
            <p v-if="!roleList.length" class="flex-center">
              暂无数据，请先配置角色
            </p>
          </template>
        </el-card></template
      >
      <el-empty
        v-if="!currentId"
        style="margin-top:200px"
        description="暂无数据，请点击左侧列表"
      />
    </div>
    <addDialog
      :showDialog="showAddDialog"
      :userList="allUser"
      @closeDialog="closeAddDialogFn"
      @sureDialog="sureAddDialogFn"
    />
  </div>
</template>

<script>
import {
  space_users,
  add_space_user,
  get_authored_users,
  set_space_user_role,
  remove_space_user_role,
  remove_user
} from '@/api/space_users';

import { users } from '@/api/userLimit';
import { roles } from '@/api/space_roles';

export default {
  name: 'users',
  components: {
    addDialog: () => import('./components/add_user.vue')
  },
  data() {
    return {
      userList: [],
      leftForm: {
        filter: ''
      },
      loadingList: false,
      currentId: null,
      userDetial: {},
      cardList: [
        { name: '基本信息', id: 'base' },
        { name: '企业微信绑定', id: 'weChat' },
        // { name: '状态', id: 'status' },
        { name: '空间角色', id: 'role' }
      ],
      roleList: [],
      showAddDialog: false,
      authoredRoles: {}, //已授权角色
      allUserList: {},
      allUser: []
    };
  },
  mounted() {
    // this.spaceName = unescape(this.$route.params.name)
  },
  activated() {
    this.allUserList = {};
    this.roleList = [];
    this.allUser = [];
    this.spaceId = this.$route.params.spaceId.toString();
    this.getAllUserList(() => {
      this.get_users(() => {
        if (!this.userList.length) return;
        this.get_space_roles(() => {
          if (this.ifHasCurrentId()) {
            this.get_authored_users(this.currentId);
          } else {
            this.currentId = null;
          }
        });
      });
    });
  },

  methods: {
    ifHasCurrentId() {
      let a = this.userList.filter(cell => {
        return cell.spaceUserId.toString() == this.currentId;
      });
      return a.length ? true : false;
    },
    searchFn() {
      this.get_users();
    },
    //所有用户
    async getAllUserList(cb) {
      const res = await users({
        pageNumber: 1,
        pageSize: 1000000
      });

      if (res && res.content.length) {
        res.content.forEach(cell => {
          this.allUserList[cell.userId.toString()] = cell;
        });
      } else {
        this.allUserList = {};
      }
      cb && cb();
    },
    refreshListFn() {
      if (!this.userList.length) return;
      this.get_users(() => {
        this.$message.success('刷新成功');
      });
    },
    async get_users(cb) {
      // console.log(this.allUserList, 'this.allUserList===')
      this.loadingList = true;
      const res = await space_users({ spaceId: this.spaceId });
      try {
        this.userList = res.map(cell => {
          return {
            ...cell,
            name: this.allUserList[cell.userId.toString()]
              ? this.allUserList[cell.userId.toString()].nickname
              : '',
            detial: this.allUserList[cell.userId.toString()]
              ? this.allUserList[cell.userId.toString()]
              : {}
          };
        });
        this.loadingList = false;
        cb && cb();
      } catch {
        this.loadingList = false;
      }
      // console.log(this.userList)
    },
    selectUserFn(v) {
      // console.log(v)
      this.userDetial = v;
      this.currentId = v.spaceUserId.toString();
      this.get_authored_users(this.currentId);
    },
    addFn() {
      this.showAddDialog = true;
      this.filterSeclectUsers();
    },
    filterSeclectUsers() {
      let user_ = [...this.userList];
      let allUser = { ...this.allUserList };
      let obj = {};
      user_.forEach(cell => {
        obj[cell.userId.toString()] = cell;
      });
      for (let id in allUser) {
        const v = allUser[id];
        v.checked = obj[id] ? true : false;
      }
      this.allUser = Object.values(allUser);
    },
    closeAddDialogFn() {
      this.showAddDialog = false;
    },
    sureAddDialogFn(v) {
      let a = { ...v };
      a.userId = a.userId.toString();
      add_space_user({
        ...a,
        ...{ spaceId: this.spaceId }
      }).then(res => {
        this.$message.success('新增成功');
        this.get_users();
        this.showAddDialog = false;
      });
    },
    saveFn() {
      if (!this.checRoles.length) {
        return;
      }
    },
    //角色列表
    async get_space_roles(cb) {
      const res = await roles({ spaceId: this.spaceId });
      this.roleList = res || [];
      cb && cb();
    },
    // 已授权的角色
    async get_authored_users(spaceUserId) {
      const res = await get_authored_users({ spaceUserId: spaceUserId });
      this.authoredRoles = {};
      if (res.length) {
        res.forEach(cell => {
          this.authoredRoles[cell.spaceRoleId.toString()] = cell;
        });
      }
      // console.log(this.authoredRoles, '已授权');

      this.setRoleStatus();
    },
    setRoleStatus() {
      let a = [...this.roleList];
      a.forEach((ele, idx) => {
        ele.checked = false;
        ele.checked = this.authoredRoles[ele.tenantRoleId.toString()]
          ? true
          : false;
      });
      this.roleList = [...a];
    },
    //授权
    changeRoleFn(v, e) {
      // console.log(v, e)
      let params = {
        spaceRoleId: v.tenantRoleId.toString(), //空间角色ID
        spaceUserId: this.currentId //空间用户id
      };
      // return
      if (e) {
        //绑定
        set_space_user_role(params).then(res => {
          this.$message.success('授权成功');
          this.get_authored_users(this.currentId);
        });
      } else {
        //取消
        remove_space_user_role(params).then(res => {
          this.$message.success('已取消授权');
          this.get_authored_users(this.currentId);
        });
      }
    },
    deleUserFn(row) {
      remove_user({
        spaceId: this.spaceId,
        userId: row.userId.toString()
      }).then(res => {
        this.$message.success('删除成功');
        this.currentId = null;
        this.get_users();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
.header {
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
}
.list {
  margin-top: 8px;
  height: calc(100% - 60px);
  .text {
    padding: 5px 10px;
    line-height: 20px;
    margin-bottom: 8px;
    .deleBtn {
      display: none;
      padding: 2px;
    }
    &:hover {
      background: #e8f1ff;
      .deleBtn {
        display: inline-block;
      }
    }
  }
  .active {
    background: #e8f1ff;
    .deleBtn {
      display: inline-block;
    }
  }
  .circle {
    padding: 6px;
    border-radius: 50%;
    margin-left: 8px;
  }
  .green {
    background: #67c23a;
  }
  .red {
    background: red;
  }
  .yellow {
    background: #f5f53a;
  }
}
.box-card {
  margin-top: 10px;
  .line-box {
    line-height: 32px;
    margin-bottom: 10px;
    // display: flex;
    // justify-content: flex-start;
    // align-items: center;
    b {
      width: 140px;
      text-align: right;
      display: inline-block;
      margin-right: 5%;
    }
    .text {
      min-width: 200px;
      text-align: left;
      display: inline-block;
    }
  }
}
.save {
  margin: 20px auto;
}
.checkName {
  margin-bottom: 10px;
  width: 30%;

  span {
    width: 100%;
    display: inline-grid;
    white-space: pre-line;
    word-wrap: break-word;
    overflow: hidden;
    line-height: 20px;
  }
}
</style>
