import request from "@/utils/request"

// // 获取空间下用户
// export function users (params) {
//   return request({
//       url: `/manage/space/${params.spaceId}/user`,
//       method: "get",
//       params
//   })
// }
// 获取空间用户
export function space_users (data) {
  return request({
      url: `/manage/space/${data.spaceId}/spaceuser`,
      method: "put",
      // data
  })
}

// 创建空间用户
export function add_space_user (params) {
  return request({
      url: `/manage/space/user`,
      method: "put",
      params
  })
}
// 查询.已经授权空间用户角色
export function get_authored_users (data) {
  return request({
      url: `/manage/space/user/${data.spaceUserId}/role`,
      method: "get",
      // data
  })
}
// 空间用户角色授权
export function set_space_user_role (params) {
  return request({
      url: `/manage/space/user/role`,
      method: "put",
      params
  })
}
// 取消空间用户角色授权
export function remove_space_user_role (params) {
  return request({
      url: `/manage/space/user/role`,
      method: "delete",
      params
  })
}
// 删除.空间用户
export function remove_user (params) {
  return request({
      url: `/manage/space/user`,
      method: "delete",
      params
  })
}

